export default {
  attemptToReconnect: 'Se încearcă reconectarea...',
  betFor: 'pentru',
  bettingTimeLeft: 'Timp de pariere rămas',
  coldField: 'Câmp rece',
  connected: 'Conectat',
  connecting: 'Se conectează',
  disconnected: 'Deconectat',
  eighthFinals: 'Optimi de finală',
  finalist: 'Finalist',
  finals: 'Finală',
  gameDeactivated: 'Joc dezactivat!',
  headToHead: '1 la 1',
  hotField: 'Câmp fierbinte',
  knockoutStage: 'Fază eliminatorie',
  latestRaces: 'Ultimele curse',
  loading: 'Încărcare',
  outrights: 'Antepost',
  problemInCommunication: 'Există o problemă de comunicare cu serverul!',
  problemWithGame: 'A apărut o problemă legată de joc, vă rugăm să așteptați!',
  productName: 'Cursa virtuală de mașini',
  qualifiesTo: 'Se califică în',
  quarterFinalist: 'Cățărătorul la sferturi',
  quarterFinals: 'Sferturi de finală',
  raceNo: 'Nr. cursei',
  raceNow: 'acum',
  racerOut: 'elim.',
  raceSchedule: 'Calendarul cursei',
  raceTime: 'Ora cursei',
  raceWinner: 'Câștigătorul cursei',
  reconnected: 'Reconectat',
  results: 'Rezultate',
  round: 'Rundă',
  roundIn: 'în',
  roundNumber: 'Numărul rundei',
  roundStartsIn: 'Runda {{roundNumber}} începe în',
  semiFinalist: 'Semifinalist',
  semiFinals: 'Semifinale',
  statistics: 'statistici',
  tournament: 'Turneu',
  tournamentNo: 'Nr. turneului',
  tournamentOutrights: 'Antepostul turneului',
  tournamentWinner: 'Câștigătorul turneului',
  upcoming: 'Urmează',
  waitingForTheRace: 'Se așteaptă cursa',
  winner: 'Castigator',
  winnerShort: 'c',
};
